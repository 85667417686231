import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carreira',
  templateUrl: './carreira.component.html',
  styleUrls: ['./carreira.component.scss']
})
export class CarreiraComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
