import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-menusol',
  templateUrl: './menusol.component.html',
  styleUrls: ['./menusol.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenusolComponent implements OnInit {
  deviceInfo = null;
  loged = false;
  nomecli = '';
  idcli = '';
  mobile = false;
  constructor(private router: Router, private deviceService: DeviceDetectorService) {
    this.epicFunction();
   }

   // tslint:disable-next-line: typedef
   epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isMobile === true || isTablet === true){
        this.mobile = true;
        console.log('is mobile');
    }
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  // tslint:disable-next-line: typedef
  ngOnInit() {
  
}

 inicio (){
    this.router.navigateByUrl('/');
  }
  datasol (){
    this.router.navigateByUrl('/DataSol');
  }

  appsollux (){
    this.router.navigateByUrl('/appsollux');
  }

  projetos (){
    this.router.navigateByUrl('/projetos');
  }

  cursos (){
    this.router.navigateByUrl('/cursos');
  }

  servicos (){
    this.router.navigateByUrl('/servicos');
  }
  contatos (){
    this.router.navigateByUrl('/contatos');
  }

  login (){
      if ( this.loged === false ) {
        this.router.navigateByUrl('/login');
      }
  }


}

