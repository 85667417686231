import {Component, Inject, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {interval} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {navigation} from '../navigation/navigation';
import {locale as navigationEnglish} from '../navigation/i18n/en';
import {locale as navigationTurkish} from '../navigation/i18n/tr';
import {DOCUMENT} from '@angular/common';
import {FuseConfigService} from '../../@fuse/services/config.service';
import {FuseNavigationService} from '../../@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '../../@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '../../@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';
import {IImage} from '../modules/slideshow/IImage';
import * as $ from 'jquery';
import { fuseAnimations } from '@fuse/animations';
import { DeviceDetectorService } from 'ngx-device-detector';


import {
    trigger,
    state,
    style,
    animate,
    transition,
    // ...
} from '@angular/animations';


const timeInterval$ = interval(10000);

timeInterval$.pipe(
    map(() => {
        console.log('123');
    })
);


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations : fuseAnimations

})

export class HomeComponent implements OnInit {
    public index;
    fuseConfig: any;
    navigation: any;
    imageUrls: (string | IImage)[] = [
        {url: 'assets/images/1.jpg'},
        {url: 'assets/images/datasolo.png'},
        {url: 'assets/images/2.jpg'},
        {url: 'assets/images/3a.png'},

    ];
    height = '50vw';
    minHeight: string;
    arrowSize = '0';
    showArrows  = false;
    disableSwiping = false;
    autoPlay  = true;
    autoPlayInterval = 7000;
    stopAutoPlayOnSlide = true;
    debug  = false;
    backgroundSize  = 'cover';
    backgroundPosition  = 'center center';
    backgroundRepeat  = 'no-repeat';
    showDots = true;
    dotColor = '#FFF';
    showCaptions  = false;
    captionColor  = '#FFF';
    captionBackground  = 'transparent';
    lazyLoad  = false;
    hideOnNoSlides  = false;
    deviceInfo = null;
    private _unsubscribeAll: Subject<any>;


    constructor(@Inject(DOCUMENT) private document: any,
                private _fuseConfigService: FuseConfigService,
                private _fuseNavigationService: FuseNavigationService,
                private _fuseSidebarService: FuseSidebarService,
                private _fuseSplashScreenService: FuseSplashScreenService,
                private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                private _translateService: TranslateService,
                private _platform: Platform,
                private deviceService: DeviceDetectorService
                ) {
        this.openMenu();
        this.epicFunction();
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('pt');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }
       epicFunction() {
              console.log('hello `Home` component');
              this.deviceInfo = this.deviceService.getDeviceInfo();
              const isMobile = this.deviceService.isMobile();
              const isTablet = this.deviceService.isTablet();
              const isDesktopDevice = this.deviceService.isDesktop();
              console.log(this.deviceInfo);
              console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
              console.log(isTablet);  // returns if the device us a tablet (iPad etc)
              console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
            }
    ngOnInit(): void {

        /*setTimeout(() => {

        }, 2000);
*/


        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

   // tslint:disable-next-line: typedef
   openMenu(){

       $('body').addClass('noScroll');


       if ($('.collapse').hasClass('collapse-active')) {
            $('.collapse').removeClass('collapse-active');
        }
        else {
            $('.collapse').addClass('collapse-active');
        }
    }


}
