
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatExpansionModule, MatIconModule, MatToolbarModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { FaqComponent } from 'app/help/faq.component';

const routes = [
    {
        path     : 'help',
        component: FaqComponent,
    }
];

@NgModule({
    declarations: [
        FaqComponent,
     
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatToolbarModule,
        MatExpansionModule,
        MatIconModule,

        FuseSharedModule
    ]

})
export class FaqModule
{
}
