import { fuseAnimations } from '@fuse/animations';
import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import { DeviceDetectorService } from 'ngx-device-detector';



@Component({
    selector: 'app-solucoes',
    templateUrl: './solucoes.component.html',
    styleUrls: ['./solucoes.component.scss'],    
    animations : fuseAnimations

})
export class SolucoesComponent implements OnInit {
    deviceInfo = null;
    mobile = false;
    constructor(private deviceService: DeviceDetectorService) {
        this.epicFunction();
        this.openMenu();
    };

    epicFunction() {
              this.deviceInfo = this.deviceService.getDeviceInfo();
              const isMobile = this.deviceService.isMobile();
              const isTablet = this.deviceService.isTablet();
              const isDesktopDevice = this.deviceService.isDesktop();
              if (isMobile === true || isTablet === true){
                     this.mobile = true;
                    }
              console.log(this.deviceInfo);
              console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
              console.log(isTablet);  // returns if the device us a tablet (iPad etc)
              console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
            }

    ngOnInit() {
    }

    openMenu(){
        $('body').removeClass('noScroll');
        if ($('.collapse').hasClass('collapse-active')) {
            $('.collapse').removeClass('collapse-active');
        }
        else {
            $('.collapse').addClass('collapse-active');
        }
    }
}
