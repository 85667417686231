import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-footersol',
  templateUrl: './footersol.component.html',
  styleUrls: ['./footersol.component.scss']
})
export class FootersolComponent implements OnInit {
  deviceInfo = null;
  loged = false;
  nomecli = '';
  idcli = '';
  mobile = false;
  constructor(private deviceService: DeviceDetectorService) {  this.epicFunction();}

  ngOnInit() {
  }

  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isMobile === true || isTablet === true){
        this.mobile = true;
        console.log('is mobile');
    }
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

}
