import { ProfileComponent } from 'app/main/pages/profile/profile.component';
import { Login2Component } from './main/pages/authentication/login-2/login-2.component';
import { AcademyCoursesComponent } from 'app/main/apps/academy/courses/courses.component';
import { AcademyCourseComponent } from 'app/main/apps/academy/course/course.component';
import { AcademyCourseService } from 'app/main/apps/academy/course.service';
import { FakeDbService } from './fake-db/fake-db.service';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { AcademyModule } from './main/apps/academy/academy.module';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';

import {
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatGridListModule,
    MatSelectModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AboutComponent } from './quemsomos/about.component';
import { HomeComponent } from './home/home.component';
import {ProjetosComponent} from './projetos/projetos.component';
import {SolucoesComponent} from './solucoes/solucoes.component';
//  import { SlideshowModule } from '../../public_api';
import { LoginComponent } from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {ForgotPasswordModule} from './auth/forgot-password/forgot-password.module';
// tslint:disable-next-line: import-spacing
import {FaqModule}  from './help/faq.module';
import { HeadersolComponent } from './headersol/headersol.component';
import { MenusolComponent } from './menusol/menusol.component';
import { AjudaComponent } from './ajuda/ajuda.component';
import { FootersolComponent } from './footersol/footersol.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { PricingModule } from './main/pages/pricing/pricing.module';
import { PricingStyle3Component } from './main/pages/pricing/style-3/style-3.component';
import { PricingStyle1Component } from './main/pages/pricing/style-1/style-1.component';
import { PricingStyle2Component } from './main/pages/pricing/style-2/style-2.component';
import {SlideshowModule} from 'ng-simple-slideshow';
import { AcademyCoursesService } from './main/apps/academy/courses.service';
import { AppsolluxComponent } from './solucoes/appsollux/appsollux.component';
import { CursodetailComponent } from './cursodetail/cursodetail.component';
import { ServicosComponent } from './servicos/servicos.component';
import { CarreiraComponent } from './carreira/carreira.component';
import { ContatosComponent } from './contatos/contatos.component';
import {MatMenuModule} from '@angular/material/menu';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

const appRoutes: Routes = [
    {
        path      : '',
        component: HomeComponent
    },
    {
        path      : 'detalhecursos',
        component: CursodetailComponent
    },
    {
        path      : 'projetos',
        component: ProjetosComponent
    },
    {
        path      : 'mesturbo',
        component: AjudaComponent
    },
    {
        path      : 'servicos',
        component: ServicosComponent
    },
    {
        path      : 'soluções',
        component: RegisterComponent
    },
    {
        path      : 'appsollux',
    component: AppsolluxComponent
    },
    {
        path      : 'DataSol',
        component: SolucoesComponent
    },
    {
        path      : 'carreiras',
        component: CarreiraComponent
    },
    {
        path      : 'contatos',
        component : ContatosComponent
    },
    {
        path      : 'login',
        component : Login2Component
    },
    {
        path      : 'register',
        component : RegisterComponent
    },
    {
        path      : 'forgot-password',
        component : ForgotPasswordComponent
    },
    {
        path     : 'cursos',
        component: AcademyCoursesComponent,
        resolve  : {
            academy: AcademyCoursesService
        }
    },
    {
        path     : 'courses/:courseId/:courseSlug',
        component: AcademyCourseComponent,
        resolve  : {
            academy: AcademyCourseService
        }
    },


];

@NgModule({
    declarations: [
        AcademyCourseComponent,
        AcademyCoursesComponent,
        AppComponent,
        AboutComponent,
        HomeComponent,
        ProjetosComponent,
        SolucoesComponent,
        LoginComponent,
        RegisterComponent,
        HeadersolComponent,
        MenusolComponent,
        AjudaComponent,
        FootersolComponent,
        PricingStyle3Component,     
        PricingStyle1Component,
        PricingStyle2Component,
        AppsolluxComponent,
        CursodetailComponent,
        ServicosComponent,
        CarreiraComponent,
        ContatosComponent,
        Login2Component,
        ProfileComponent,
        NavbarComponent,
        FooterComponent
    ],
    imports     : [
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),
        SlideshowModule,
        BrowserModule,
        PricingModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        MatGridListModule,
        NgxGalleryModule,
        // Material
        MatCardModule,
        MatSidenavModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        HttpClientModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        SampleModule,
        ForgotPasswordModule,
        FaqModule,
        AcademyModule,
        MatSelectModule,
        MatMenuModule,
        DeviceDetectorModule 
        
    ],
    schemas: [ NO_ERRORS_SCHEMA ],

    bootstrap   : [
        AppComponent
    ],
    providers   : [
        AcademyCoursesService,
        AcademyCourseService
    ],
    exports: [MenusolComponent],
})
export class AppModule
{
}
