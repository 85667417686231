import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appsollux',
  templateUrl: './appsollux.component.html',
  styleUrls: ['./appsollux.component.scss']
})
export class AppsolluxComponent implements OnInit {

  constructor(private router: Router) { }

  // tslint:disable-next-line: typedef
  ngOnInit() {
  }

 

}
