import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'app/services.service';
import { AcademyCoursesService } from 'app/main/apps/academy/courses.service';

@Component({
  selector: 'app-cursodetail',
  templateUrl: './cursodetail.component.html',
  styleUrls: ['./cursodetail.component.scss']
})
export class CursodetailComponent implements OnInit {
    Cursos: any;
  constructor( private service: AcademyCoursesService) { }

  ngOnInit(): void {
this.detalhecursos();
}


  detalhecursos(): void {

    this.service.detalhecurso({id: '1'}).subscribe(dados => {
    if (dados !== this.Cursos) {
    this.Cursos = dados;
    console.log(this.Cursos);
    }
    }); 
}

}
