import { ServicesService } from './../services.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { fuseAnimations } from '@fuse/animations';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations : fuseAnimations
})
export class AboutComponent implements OnInit {
    nome: string;
    email: string;
    enviado = true;
    telefone: string;
    msg: string;
    validamail: any;
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    mobile = false;
    deviceInfo = null;


    private _unsubscribeAll: Subject<any>;

    constructor(private service: ServicesService, private deviceService: DeviceDetectorService) {
        this.openMenu();
        this.epicFunction();
      }
  
// tslint:disable-next-line: typedef
epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isMobile === true || isTablet === true){
        this.mobile = true;
    }
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  // tslint:disable-next-line: typedef
  ngOnInit() {

    this.galleryOptions = [
        {
            width: '600px',
            height: '500px',
            thumbnailsColumns: 4,
            thumbnailsRows: 2,
            imageAutoPlay: true,
            imageAnimation: NgxGalleryAnimation.Slide
        },
        // max-width 800
        {
            breakpoint: 800,
            width: '100%',
            height: '600px',
            imagePercent: 80,
            thumbnailsPercent: 20,
            thumbnailsMargin: 20,
            thumbnailMargin: 20
        },
        // max-width 400
        {
            breakpoint: 400,
            preview: false
        }
    ];

    this.galleryImages = [
        {
            small: 'assets/images/portfolio/1s.png',
            medium: 'assets/images/portfolio/1m.png',
            big: 'assets/images/portfolio/1.png'
        },
        {
            small: 'assets/images/portfolio/2s.png',
            medium: 'assets/images/portfolio/2m.png',
            big: 'assets/images/portfolio/2.png'
        },
        {
            small: 'assets/images/portfolio/3s.png',
            medium: 'assets/images/portfolio/3m.png',
            big: 'assets/images/portfolio/3.png'
        },
        {
            small: 'assets/images/portfolio/4s.png',
            medium: 'assets/images/portfolio/4m.png',
            big: 'assets/images/portfolio/4.png'
        },
        {
            small: 'assets/images/portfolio/5s.png',
            medium: 'assets/images/portfolio/5m.png',
            big: 'assets/images/portfolio/5.png'
        },
        {
            small: 'assets/images/portfolio/6s.png',
            medium: 'assets/images/portfolio/6m.png',
            big: 'assets/images/portfolio/6.png'
        },
        {
            small: 'assets/images/portfolio/7s.png',
            medium: 'assets/images/portfolio/7m.png',
            big: 'assets/images/portfolio/7.png'
        },
        {
            small: 'assets/images/portfolio/8s.png',
            medium: 'assets/images/portfolio/8m.png',
            big: 'assets/images/portfolio/8.png'
        },
        {
            small: 'assets/images/portfolio/9s.png',
            medium: 'assets/images/portfolio/9m.png',
            big: 'assets/images/portfolio/9.png'
        },
        {
            small: 'assets/images/portfolio/10s.png',
            medium: 'assets/images/portfolio/10m.png',
            big: 'assets/images/portfolio/10.png'
        },
        {
            small: 'assets/images/portfolio/11s.png',
            medium: 'assets/images/portfolio/11m.png',
            big: 'assets/images/portfolio/11.png'
        }
    ];
}
  

    // tslint:disable-next-line: typedef
    openMenu(){
        $('body').removeClass('noScroll');

        if ($('.collapse').hasClass('collapse-active')) {
            $('.collapse').removeClass('collapse-active');
        }
        else {
            $('.collapse').addClass('collapse-active');
        }
    }

enviamail(){
this.service.formsend({
    nome: this.nome,
    msg: this.msg,
    email: this.email,
    telefone: this.telefone
      })
        .subscribe(dados => {
          if (dados !== this.validamail) {
            this.validamail = dados;
            if(this.validamail.enviado === '0'){
                this.enviado = false;
            }
          }
        });
    }
    

}
