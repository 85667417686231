import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-headersol',
  templateUrl: './headersol.component.html',
  styleUrls: ['./headersol.component.scss']
})
export class HeadersolComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
