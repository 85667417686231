import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, delay, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ServicesService {
private readonly API = 'https://www.sollux.dev/api/_functions/consultas/cursos';




  constructor(private http: HttpClient) { }

  // tslint:disable-next-line: typedef
  getcursos() {
    return this.http.get<any[]>(this.API)
  .pipe(tap());
  }

  // CONTATO
    // tslint:disable-next-line: typedef
    formsend(paramns2) {
    return this.http.post<any[]>('https://www.sollux.dev/api/_functions/sendmail/emailsite',  paramns2 )
  .pipe(tap());
  }
}
