import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'app/services.service';

@Component({
  selector: 'app-contatos',
  templateUrl: './contatos.component.html',
  styleUrls: ['./contatos.component.scss']
})
export class ContatosComponent implements OnInit {
    nome: string;
    email: string;
    enviado = false;
    telefone: string;
    msg: string;
    validamail: any;
  
    constructor(private service: ServicesService) {}
    
  ngOnInit() {
  }

  enviamail(){
    this.service.formsend({
        nome: this.nome,
        msga: this.msg,
        email: this.email,
        tel: this.telefone
          })
            .subscribe(dados => {
              if (dados !== this.validamail) {
                this.validamail = dados;
                if(this.validamail.enviado === '0'){
                    this.enviado = false;
                }
                if(this.validamail.enviado === '1'){
                  this.enviado = true;
              }
              }
            });
        }

}
